<script>
/* eslint-disable vue/no-v-html */
</script>
<template>
  <b-modal
    id="plu-devices-modal-escludi-reparti-multipli"
    ref="plu-devices-modal-escludi-reparti-multipli"
    :title="title"
    v-model="show"
    bg-variant="white"
    backdrop-variant="dark"
    backdrop
    centered
    :no-close-on-backdrop="true"
    hide-footer
    @open="getProducts"
  >
    <b-row class="mx-50 my-1">
      <h5>Plu selezionati:</h5>
      <b-col cols="12" class="d-flex">
        <div v-for="(plu, index) in plui" :key="index" class="mr-1">
          <b-badge variant="primary">{{ plu.tasto }}</b-badge>
        </div>
      </b-col>
    </b-row>
    <b-row class="mx-50">
      <h5 class="">Seleziona Reparti da associare al Plu</h5>

      <b-col cols="12" class="">
        <b-form-checkbox
          id="reparti"
          value="Y"
          unchecked-value="N"
          v-model="selectAllReparti"
          @change="checkAllReparti"
        >
          <b> Seleziona Tutti </b>
        </b-form-checkbox>
      </b-col>
      <!-- <b-col cols="12">
            <b-form-checkbox-group
              id="checkbox-reparti"
              v-model="repartiSelected"
              name="flavour-2"
              class="demo-inline-spacing"
              @change="onChangeRepartiList"
            >
              <b-form-checkbox
                v-for="(rep, index) in repartiList"
                :key="index"
                :value="rep"
              >
                {{ rep.nome }}
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-col> -->
      <b-col>
        <b-form-checkbox-group
          id="checkbox-group-2"
          v-model="repartiSelected"
          name="flavour-2"
          class="demo-inline-spacing"
        >
          <b-form-checkbox
            v-for="(rep, index) in repartiList"
            :key="index"
            :value="rep"
          >
            {{ rep.nome }}
          </b-form-checkbox>
        </b-form-checkbox-group>
      </b-col>
      {{ repartiSelected }}
      {{ selectAllReparti }}
    </b-row>
    <b-row class="">
      <b-col class="d-flex justify-content-end">
        <b-button @click="setPlu">Salva</b-button>
      </b-col>
    </b-row>
    <ejs-toast id="toast_type" ref="toastRef" :position="{ X: 'Right' }" />
  </b-modal>
</template>

<script>
import Vue from "vue";
import { required } from "@validations";
import {
  BModal,
  BRow,
  BCol,
  BFormCheckbox,
  BBadge,
  BFormCheckboxGroup,
  /* BFormRadio,  BLink, */ BButton, // BFormGroup, BImg, BFormFile,
} from "bootstrap-vue";
// import vSelect from 'vue-select'
import { ToastPlugin } from "@syncfusion/ej2-vue-notifications";
import { getProdotti } from "@/@core/api-service/catalog/products";
import { getReparti } from "@/@core/api-service/logistic/reparto";
import { setPlui } from "@/@core/api-service/devices/Plu";

Vue.use(ToastPlugin);

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BBadge,
    BFormCheckbox,
    BFormCheckboxGroup,

    // BFormRadio,
    // BLink,
    // BFormTextarea,
    BButton,
    /* BFormGroup,
    vSelect,
    BImg,
    BFormFile, */
  },
  watch: {
    repartiSelected() {
      if (this.repartiSelected.length === this.repartiList.length)
        this.selectAllReparti = "Y";
      else this.selectAllReparti = "N";
    },
  },
  data() {
    return {
      plui: [],
      plu: {
        id: 0,
        tasto: "",
        descrizione: "",
        idProdotto: "",
        prodotto: {
          id: "",
          nome: "",
        },
        reparti: [],
        escludiReparti: "N",
      },
      selectAllReparti: "N",
      repartiSelected: [],
      repartiAssociati: [],
      optionProdottiAssociati: [],
      configurazionePlu: {
        codice: "",
        prodottoAssociato: {},
        repartiAssociati: [],
      },
      advancedEditing: false,
      show: false,
      repartiList: [],
      title: "",
      tipoPrezzo: [
        {
          id: 0,
          label: "0 - plu a peso",
        },
        {
          id: 1,
          label: "1 - plu a pezzo",
        },
        {
          id: 13,
          label: "13 - plu a pezzo pesato",
        },
      ],
      tipoTesto: [
        {
          id: 0,
          label: "0 - non attivato",
        },
        {
          id: 1,
          label: "1 - display",
        },
        {
          id: 2,
          label: "2 -display e stampa",
        },
        {
          id: 3,
          label: "3 stampa senza conferma",
        },
      ],
      tracciabilita: [
        {
          id: 0,
          label: "000 - NO",
        },
        {
          id: 1,
          label: "102 - si per codice breve",
        },
        {
          id: 2,
          label: "101 - per testi diretti o secondo stile",
        },
        {
          id: 3,
          label: "104 - per genere merceologico etica/ obbligo ins",
        },
        {
          id: 4,
          label: "106 - per tracciabilità manuale",
        },
      ],
    };
  },
  computed: {
    repartiAssociatiComputed() {
      // console.log(this.plu.reparti)
      return this.repartiAssociati;
    },
    list2Computed() {
      // console.log(this.repartiList.filter(item => !this.list1.includes(item)))
      return this.repartiList;
    },
  },
  mounted() {
    getReparti()
      .then((res) => {
        // console.log(res)
        if (res.data.esito === "OK") {
          this.repartiList = [...res.data.reparti];
          this.list2Reset = [...this.repartiList];
          // console.log(this.repartiList)
        }
      })
      .catch((e) => e);
  },
  methods: {
    setPluiByFather(plui) {
      this.plui = [...plui];
    },
    checkAllReparti() {
      if (this.selectAllReparti === "Y") {
        this.repartiSelected = [...this.repartiList];
      } else this.repartiSelected.splice(0);
    },
    setPlu() {
      this.plu = this.plui.map((el) => ({
        ...el,
        reparti: this.repartiSelected,
        id_puntovendita: this.$route.params.shop,
        escludiReparti: 'N'
      }));
      setPlui(JSON.stringify(this.plu)).then((res) => {
        // console.log('ressss', res)
        if (res.data.esito === "OK") {
          console.log("oksetplu", res);
          const plu = res.data.plu.map((elemento) => {
            const element = {
              id: elemento.id,
              prodotto: elemento.id_prodotto,
              nome: elemento.nomeProdotto,
              tasto: elemento.tasto,
              reparti: [...elemento.reparti],
              codiceProdotto: elemento.codiceProdotto,
            };
            return element;
          });
          this.$emit("update-plu", plu);
          this.$refs["plu-devices-modal-escludi-reparti-multipli"].hide();
        } else if (res.data.esito === "KO") {
          this.$refs.toastRef.show({
            title: "Errore Inserimento",
            content: res.data.messaggio,
            cssClass: "e-toast-danger",
            icon: "e-error toast-icons",
          });
        }
      });
    },
    /* this.plu.reparti = [...this.list1.map(el => ({ id: el.id }))]
    this.plu.prodotto = String(this.plu.prodotto) */
    // console.log('before send', JSON.stringify([this.plu]))
    getProducts() {
      getProdotti()
        .then((res) => {
          // console.log('prodotti', res)
          if (res.data.esito === "OK") {
            this.optionProdottiAssociati = [...res.data.prodotti].filter(
              (el) => el.tipoProdotto === "Unificato"
            );
            // console.log(this.optionProdottiAssociati)
          }
        })
        .catch((e) => e);
    },
    setTitle(title) {
      this.title = title;
    },
    setPluSidebar(plu) {
      console.log("plu", plu);
      this.repartiSelected = plu.reparti.map(({ id, codice, nome }) => ({
        value: id,
        codice,
        text: nome,
      }));
      this.plu = { ...plu, escludiReparti: "N" };
    },
    openSidebar() {
      this.show = true;
    },
    checkMove(args) {
      // console.log(args)
      const elementMoved = { ...args.draggedContext.element };
      const elementExist = this.repartiAssociati.some(
        (el) => el.id === elementMoved.id
      );
      if (elementExist) return false;
      return true;
    },
    onChangeRepartiList() {
      console.log(this.repartiSelected.length);
      console.log(this.repartiList.length);
      if (this.repartiSelected.length === this.repartiList.length)
        this.selectAllReparti = "Y";
      else {
        this.selectAllReparti = "N";
        this.repartiSelected.length = 0;
      }
    },
    resetPlu() {
      this.plu = {
        id: 0,
        tasto: "",
        descrizione: "",
        idProdotto: "",
        prodotto: {
          id: "",
          nome: "",
        },
        reparti: [],
        escludiReparti: "N",
      };
      this.repartiList = [...this.list2Reset];
      this.repartiAssociati.length = 0;
    },
  },
};
</script>

<style>
#toast_type {
  z-index: 99999 !important;
}
</style>
