var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{ref:"plu-devices-sidebar-multipli",attrs:{"id":"plu-devices-sidebar-multipli","sidebar-class":"sidebar-40","bg-variant":"white","backdrop-variant":"dark","backdrop":"","no-close-on-backdrop":true,"shadow":"","no-header":"","right":""},on:{"shown":_vm.getProducts},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',[_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":function($event){return hide()}}})],1)]),_c('ValidationObserver',[_c('b-row',{staticClass:"m-1"},[_c('b-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Tasto PLU","label-for":"cod-plu","label-class":"font-weight-bolder"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"cod-plu","placeholder":"PLU","type":"number"},model:{value:(_vm.plu.tasto),callback:function ($$v) {_vm.$set(_vm.plu, "tasto", $$v)},expression:"plu.tasto"}})],1)],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":'Prodotto Associato',"label-class":"font-weight-bolder"}},[_c('validation-provider',{attrs:{"name":"Prod. Associato","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"nome","clearable":false,"value":"id","reduce":function (val) { return ({ id: val.id, nome: val.nome }); },"filterable":"","options":_vm.optionProdottiAssociati},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_c('div',[_vm._v("Ricerca prodotti...")])]}},{key:"loading",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_c('div',[_vm._v("Caricamento prodotti...")])]}},{key:"searching",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_c('div',[_vm._v("Sto cercando...")])]}},{key:"option",fn:function(option){return [_c('div',[_c('span',{class:{ 'font-weight-bold' : option.tipoProdotto === 'Unificato', 'text-muted' : option.tipoProdotto !== 'Unificato'}},[_vm._v(_vm._s(option.nome.concat(option.tipoProdotto === 'Unificato' ? '(Unificato)' : ''))+".")])])]}}],null,true),model:{value:(_vm.plu.prodotto),callback:function ($$v) {_vm.$set(_vm.plu, "prodotto", $$v)},expression:"plu.prodotto"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',{staticClass:"m-1"},[_c('b-col',{attrs:{"md":"6"}},[_c('h4',[_vm._v("Reparti Associati")]),_c('draggable',{staticClass:"mb-1 cursor-move",staticStyle:{"border-style":"dashed","height":"300px"},attrs:{"list":_vm.repartiAssociatiComputed,"group":{ name:'reparti' }}},[(_vm.repartiAssociatiComputed.length === 0)?_c('div',[_vm._v(" Nessun Reparto ")]):_vm._l((_vm.repartiAssociatiComputed),function(listItem,index){return _c('b-badge',{key:index,staticClass:"m-25",attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(listItem.codice.concat('-', listItem.nome))+" ")])})],2)],1),_c('b-col',{attrs:{"md":"6"}},[_c('h4',[_vm._v("Tutti i reparti")]),_c('draggable',{staticClass:"mb-1 cursor-move",staticStyle:{"border-style":"dashed","height":"300px"},attrs:{"list":_vm.list2,"group":{ name:'reparti' },"move":_vm.checkMove}},[(_vm.list2.length === 0)?_c('div',[_vm._v(" Nessun Reparto ")]):_vm._l((_vm.list2),function(listItem,index){return _c('b-badge',{key:index,staticClass:"m-25",attrs:{"variant":"info"}},[_vm._v(" "+_vm._s(listItem.codice.concat('-', listItem.nome))+" ")])})],2)],1),_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"cols":"12"}},[_c('b-button',{on:{"click":_vm.setPlu}},[_vm._v("Salva")])],1)],1),_c('ejs-toast',{ref:"toastRef",attrs:{"id":"toast_type","position":{ X: 'Right' }}})],1)]}}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}})}
var staticRenderFns = []

export { render, staticRenderFns }