<template>
  <b-sidebar
    id="plu-devices-sidebar-multipli"
    ref="plu-devices-sidebar-multipli"
    v-model="show"
    sidebar-class="sidebar-40"
    bg-variant="white"
    backdrop-variant="dark"
    backdrop
    :no-close-on-backdrop="true"
    shadow
    no-header
    right
    @shown="getProducts"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ title }}
        </h5>
        <div>
          <!-- <feather-icon
            v-if="title === 'Modifica Collaboratore'"
            icon="TrashIcon"
            class="cursor-pointer"
            @click="$emit('remove-event', form); hide();"
          /> -->
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide()"
          />
        </div>
      </div>
      <ValidationObserver>
        <b-row class="m-1">
          <b-col
            cols="12"
            class="d-flex"
          >
            <b-form-group
              label="Tasto PLU"
              label-for="cod-plu"
              label-class="font-weight-bolder"
            >
              <b-input-group>
                <b-form-input
                  id="cod-plu"
                  v-model="plu.tasto"
                  placeholder="PLU"
                  type="number"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            lg="6"
          >
            <b-form-group
              :label="'Prodotto Associato'"
              label-class="font-weight-bolder"
            >
              <validation-provider
                #default="{ errors }"
                name="Prod. Associato"
                rules="required"
              >
                <v-select
                  v-model="plu.prodotto"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="nome"
                  :clearable="false"
                  value="id"
                  :reduce="(val) => ({ id: val.id, nome: val.nome })"
                  filterable
                  :options="optionProdottiAssociati"
                >
                  <!-- eslint-disable-next-line vue/no-unused-vars  -->
                  <template #no-options="{ search, searching, loading }">
                    <div>Ricerca prodotti...</div>
                  </template>
                  <!-- eslint-disable-next-line vue/no-unused-vars  -->
                  <template #loading="{ search, searching, loading }">
                    <div>Caricamento prodotti...</div>
                  </template>
                  <!-- eslint-disable-next-line vue/no-unused-vars  -->
                  <template #searching="{ search, searching, loading }">
                    <div>Sto cercando...</div>
                  </template>
                  <template v-slot:option="option">
                    <div>
                      <span
                        :class="{ 'font-weight-bold' : option.tipoProdotto === 'Unificato', 'text-muted' : option.tipoProdotto !== 'Unificato'}">{{ option.nome.concat(option.tipoProdotto === 'Unificato' ? '(Unificato)' : '') }}.</span>
                    </div>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="m-1">
          <!-- tag source -->
          <b-col md="6">
            <h4>Reparti Associati</h4>
            <draggable
              :list="repartiAssociatiComputed"
              :group="{ name:'reparti' }"
              class="mb-1 cursor-move"
              style="border-style: dashed; height: 300px"
            >
              <div v-if="repartiAssociatiComputed.length === 0">
                Nessun Reparto
              </div>
              <b-badge
                v-for="(listItem, index) in repartiAssociatiComputed"
                v-else
                :key="index"
                class="m-25"
                variant="primary"
              >
                {{ listItem.codice.concat('-', listItem.nome) }}
              </b-badge>
            </draggable>
          </b-col>

          <!-- add tag -->
          <b-col md="6">
            <h4>Tutti i reparti</h4>
            <draggable
              :list="list2"
              :group="{ name:'reparti' }"
              class="mb-1 cursor-move"
              style="border-style: dashed; height: 300px"
              :move="checkMove"
            >
              <div v-if="list2.length === 0">
                Nessun Reparto
              </div>
              <b-badge
                v-for="(listItem, index) in list2"
                v-else
                :key="index"
                class="m-25"
                variant="info"
              >
                {{ listItem.codice.concat('-', listItem.nome) }}
              </b-badge>
            </draggable>
          </b-col>
          <!-- <b-col cols="6">
            <b-form-group
              label="Descrizione"
              label-for="descrizione-reparto-plu"
              label-class="font-weight-bolder"
            >
              <b-form-textarea
                id="descrizione-reparto-plu"
                v-model="plu.descrizione"
                placeholder="12211"
                rows="3"
              />
            </b-form-group>
          </b-col> -->
          <b-col
            cols="12"
            class="d-flex justify-content-end"
          >
            <b-button @click="setPlu">Salva</b-button>
          </b-col>
        </b-row>
        <!-- {{ plu }} -->
        <!--<b-row class="m-1">
          <b-col>
            <h4 @click="advancedEditing = !advancedEditing">
              <b-link>Altro...</b-link>
            </h4>
          </b-col>
        </b-row>
         <b-row
          v-if="advancedEditing === true"
          class="m-1"
        >
          <b-col cols="4">
            <b-form-group
              label="Reparto"
              label-for="id-reparto-plu"
              label-class="font-weight-bolder"
            >
              <b-form-input
                id="id-reparto-plu"
                placeholder="12211"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col cols="8">
            <b-form-group
              label="Descrizione"
              label-for="descrizione-reparto-plu"
              label-class="font-weight-bolder"
            >
              <b-form-input
                id="descrizione-reparto-plu"
                placeholder="12211"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Gruppo Merceologico"
              label-for="gruppo-merceologico-plu"
              label-class="font-weight-bolder"
            >
              <b-form-input
                id="gruppo-merceologico-plu"
                placeholder="12211"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col cols="8">
            <b-form-group
              label="Descrizione"
              label-for="gruppo-merceologico-descrizione-plu"
              label-class="font-weight-bolder"
            >
              <b-form-input
                id="gruppo-merceologico-descrizione-plu"
                placeholder="12211"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="SAN"
              label-for="SAN-plu"
              label-class="font-weight-bolder"
            >
              <b-form-input
                id="SAN-plu"
                placeholder="12211"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col cols="8">
            <b-form-group
              label="Modificabile"
              label-for="some-radio9"
              label-class="font-weight-bolder"
            >
              <div class="demo-inline-spacing">
                <b-form-radio
                  name="some-radio9"
                  value="e"
                  class="custom-control-primary my-50"
                >
                  Si
                </b-form-radio>
                <b-form-radio
                  name="some-radio9"
                  value="ff"
                  class="custom-control-secondary my-50"
                >
                  No
                </b-form-radio>
              </div>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Tipo di Prezzo"
              label-for="tipo-prezzo-plu"
              label-class="font-weight-bolder"
            >
              <v-select
                id="tipo-prezzo-plu"
                :options="tipoPrezzo"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                title="label"
                placeholder="Add Options"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Tara"
              label-for="Tara-plu"
              label-class="font-weight-bolder"
            >
              <b-form-input
                id="Tara-plu"
                placeholder="12211"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col cols="8">
            <b-form-group
              label="Descrizione"
              label-for="Tara-descrizione-plu"
              label-class="font-weight-bolder"
            >
              <b-form-input
                id="Tara-descrizione-plu"
                placeholder="12211"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="8"
          >
            <b-form-group
              label="Tipo di Testo"
              label-for="tipo-testo-plu"
              label-class="font-weight-bolder"
            >
              <v-select
                id="tipo-testo-plu"
                :options="tipoTesto"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                title="label"
                placeholder="Add Options"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="8"
          >
            <b-form-group
              label="Codice Testo"
              label-for="codice-testo-plu"
              label-class="font-weight-bolder"
            >
              <b-form-input
                id="codice-testo-plu"
                placeholder="12211"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="8"
          >
            <b-form-group
              label="Tracciabilità"
              label-for="tracciabilita-plu"
              label-class="font-weight-bolder"
            >
              <v-select
                id="tracciabilita-plu"
                :options="tracciabilita"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                title="label"
                placeholder="Add Options"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="8"
          >
            <b-form-group
              label="Iva"
              label-for="iva-plu"
              label-class="font-weight-bolder"
            >
              <b-form-input
                id="iva-plu"
                placeholder="12211"
                type="number"
              />
            </b-form-group>
          </b-col>
        </b-row> -->
        <ejs-toast
          id="toast_type"
          ref="toastRef"
          :position="{ X: 'Right' }"
        />
      </ValidationObserver>
    </template>
  </b-sidebar>
</template>

<script>
import Vue from 'vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import {
  BSidebar, BRow, BCol, BBadge, BFormGroup, BFormInput, /* BFormRadio,  BLink, */ BInputGroup, BButton, // BFormGroup, BImg, BFormFile,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import { ToastPlugin } from '@syncfusion/ej2-vue-notifications'
import draggable from 'vuedraggable'
import { useInputImageRenderer, blobToBase64 } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import { getProdotti } from '@/@core/api-service/catalog/products'
import { getReparti } from '@/@core/api-service/logistic/reparto'
import { setPlui } from '@/@core/api-service/devices/Plu'

Vue.use(ToastPlugin)

export default {
  components: {
    BSidebar,
    BRow,
    BCol,
    draggable,
    BBadge,
    vSelect,
    BFormGroup,
    BFormInput,
    // BFormRadio,
    // BLink,
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    // BFormTextarea,
    BButton,
    /* BFormGroup,
    vSelect,
    BImg,
    BFormFile, */
  },
  data() {
    return {
      plu: {
        id: 0,
        tasto: '',
        descrizione: '',
        idProdotto: '',
        prodotto: {
          id: '',
          nome: '',
        },
        reparti: [],
        escludiReparti: 'N',
      },
      required,
      repartiAssociati: [],
      optionProdottiAssociati: [],
      configurazionePlu: {
        codice: '',
        prodottoAssociato: {},
        repartiAssociati: [],

      },
      advancedEditing: false,
      show: false,
      list1: [],
      list2: [],
      list2Reset: [],
      title: '',
      tipoPrezzo: [
        {
          id: 0,
          label: '0 - plu a peso',
        },
        {
          id: 1,
          label: '1 - plu a pezzo',
        },
        {
          id: 13,
          label: '13 - plu a pezzo pesato',
        },
      ],
      tipoTesto: [
        {
          id: 0,
          label: '0 - non attivato',
        },
        {
          id: 1,
          label: '1 - display',
        },
        {
          id: 2,
          label: '2 -display e stampa',
        },
        {
          id: 3,
          label: '3 stampa senza conferma',
        },
      ],
      tracciabilita: [
        {
          id: 0,
          label: '000 - NO',
        },
        {
          id: 1,
          label: '102 - si per codice breve',
        },
        {
          id: 2,
          label: '101 - per testi diretti o secondo stile',
        },
        {
          id: 3,
          label: '104 - per genere merceologico etica/ obbligo ins',
        },
        {
          id: 4,
          label: '106 - per tracciabilità manuale',
        },
      ],
    }
  },
  computed: {
    repartiAssociatiComputed() {
      // console.log(this.plu.reparti)
      return this.repartiAssociati
    },
    list2Computed() {
      // console.log(this.list2.filter(item => !this.list1.includes(item)))
      return this.list2
    },
  },
  mounted() {
    getReparti().then(res => {
      // console.log(res)
      if (res.data.esito === 'OK') {
        this.list2 = [...res.data.reparti.map(el => ({ id: el.id, nome: el.nome, codice: el.codice }))]
        this.list2Reset = [...this.list2]
        // console.log(this.list2)
      }
    }).catch(e => e)
  },
  methods: {
    setPlu() {
      /* this.plu.reparti = [...this.list1.map(el => ({ id: el.id }))]
      this.plu.prodotto = String(this.plu.prodotto) */
      // console.log('before send', JSON.stringify([this.plu]))
      // console.log(this.plu)
      this.plu.idProdotto = this.plu.prodotto.id
      this.plu.reparti = [...this.repartiAssociati]
      if (this.plu.reparti.length >= 0) {
        // console.log(JSON.stringify([{ ...this.plu, id_puntovendita: this.$route.params.shop }]))
        setPlui(JSON.stringify([{ ...this.plu, id_puntovendita: this.$route.params.shop }])).then(res => {
          // console.log('ressss', res)
          if (res.data.esito === 'OK') {
            // console.log('oksetplu', res)
            const plu = res.data.plu.map(elemento => {
              const element = {
                id: elemento.id,
                prodotto: elemento.id_prodotto,
                nome: elemento.nomeProdotto,
                tasto: elemento.tasto,
                reparti: [...elemento.reparti],
                codiceProdotto: elemento.codiceProdotto,
              }
              return element
            })
            this.$emit('update-plu', plu)
            this.resetPlu()
            this.$refs['plu-devices-sidebar-multipli'].hide()
          } else if (res.data.esito === 'KO') {
            this.$refs.toastRef.show({
              title: 'Errore Inserimento', content: res.data.messaggio, cssClass: 'e-toast-danger', icon: 'e-error toast-icons',
            })
          }
        })
      } else {
        this.$refs.toastRef.show({
          title: 'Errore Inserimento', content: 'Devi Associare almeno un Reparto al PLU', cssClass: 'e-toast-danger', icon: 'e-error toast-icons',
        })
      }
    },
    getProducts() {
      getProdotti().then(res => {
        // console.log('prodotti', res)
        if (res.data.esito === 'OK') {
          this.optionProdottiAssociati = [...res.data.prodotti].filter(el => el.tipoProdotto === 'Unificato')
          // console.log(this.optionProdottiAssociati)
        }
      }).catch(e => e)
    },
    async previewNewImage(blob) {
      // blob.size per impostare in bytes la dimensione massima del file
      await blobToBase64(blob).then(res => {
        // console.log(res)
        if (res.substring(0, 21).includes('image')) {
          this.dataPhoto.base64 = res
        }
      }).catch(() => {
      })
    },
    setTitle(title) {
      this.title = title
    },
    setPluSidebar(plu) {
      // console.log('plu', plu)
      this.repartiAssociati = plu.reparti.map(({ id, codice, nome }) => ({ id, codice, nome }))
      this.plu = { ...plu, escludiReparti: 'N' }
    },
    openSidebar() {
      this.show = true
    },
    checkMove(args) {
      // console.log(args)
      const elementMoved = { ...args.draggedContext.element }
      const elementExist = this.repartiAssociati.some(el => el.id === elementMoved.id)
      if (elementExist) return false
      return true
    },
    resetPlu() {
      this.plu = {
        id: 0,
        tasto: '',
        descrizione: '',
        idProdotto: '',
        prodotto: {
          id: '',
          nome: '',
        },
        reparti: [],
        escludiReparti: 'N',
      }
      this.list2 = [...this.list2Reset]
      this.repartiAssociati.length = 0
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)
    // const base64data = blobToBase64(this.profileFile)
    // console.log(base64data.substr(base64data.indexOf(',') + 1))
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)
    // const { blobToBase } = blobToBase64(this.profileFile)

    return {
      refInputEl,
      previewEl,
      // blobToBase64,
      // blobToBase,
      inputImageRenderer,
    }
  },
}
</script>

<style>
#toast_type {
  z-index: 99999 !important;
}
</style>
