<script>
/* eslint-disable vue/no-v-html */
</script>
<template>
  <b-card>
    <ejs-toast
      id="toast_type"
      ref="toastRef"
      :position="{ X: 'Right' }"
    />
    <div class="col-lg-12 control-section mt-2 p-0">
      <div class="content-wrapper">
        <!-- <div>
          <ejs-dropdownlist
            id="dropdownlist"
            ref="dropdown"
            :data-source="ddData"
            width="220px"
            :fields="fields"
            :change="valueChange"
            :value="ddValue"
          />
          <span
            id="msg"
            ref="msgelement"
            v-html="loadTime"
          />
          <br>
        </div> -->
        <!-- {{ plui }} -->
        <ejs-grid
          id="overviewgrid"
          ref="overviewgrid"
          locale="it-IT"
          :data-source="plui"
          :selection-settings="selectionSettings"
          :edit-settings="editSettings"
          :filter-settings="filterOptions"
          :show-column-chooser="true"
          :allow-selection="true"
          :allow-sorting="true"
          :allow-filtering="true"
          :allow-resizing="true"
          :enable-virtualization="false"
          :allow-row-drag-and-drop="false"
          height="600"
          :allow-text-wrap="true"
          :text-wrap-settings="wrapSettings"
          :allow-pdf-export="true"
          :allow-excel-export="true"
          row-height="38"
          :enable-hover="false"
          :enable-header-focus="true"
          :load="load"
          :toolbar="toolbar"
          :toolbar-click="toolbarClick"
          :action-begin="actionTableBegin"
          :action-complete="actionTableComplete"
          :allow-grouping="false"
          :allow-paging="true"
          :page-settings="pageSettings"
          :context-menu-items="contextMenuItems"
          :context-menu-click="contextMenuClick"
          :data-bound="dataBound"
          :command-click="commandColumm"
          :row-selected="onRowSelected"
          :cell-deselecting="onDeselectRow"
        >
          <e-columns>
            <e-column
              type="checkbox"
              :allow-filtering="false"
              :allow-sorting="false"
              width="60"
            />
            <e-column
              field="id"
              :visible="false"
              header-text="ID"
              :is-primary-key="true"
              width="100"
            />
            <e-column
              field="tasto"
              header-text="tasto"
              width="150"
              :filter="filter"
              clip-mode="EllipsisWithTooltip"
            />
            <e-column
              field="nome"
              header-text="Nome"
              width="230"
              :filter="filter"
            />
            <e-column
              field="reparti"
              header-text="Reparti"
              width="500"
              :filter="filter"
              clip-mode="EllipsisWithTooltip"
              :template="shopTemplate"
              :allow-editing="false"
              :allow-filtering="false"
            />
            <!-- <e-column
              field="idProdotto"
              header-text="Nome Prodotto"
              width="230"
              :filter="filter"
            /> -->
            <e-column
              field="Azioni"
              :lock-column="true"
              header-text="Azioni"
              width="240"
              :template="cTemplate"
              :allow-editing="false"
              :commands="commands"
              :allow-sorting="false"
              :allow-filtering="false"
            />
          </e-columns>
        </ejs-grid>
      </div>
    </div>
    <!-- <SettingsPdfExport
      :show="showModalSettingPdfExport"
      @pdf-export-confirm-close="closeModal"
    /> -->
    <SidebarPluEventHandler
      ref="plu-devices-sidebar-multipli"
      @update-plu="updatePlu"
    />
    <AssociationMultiplePlu
      ref="plu-devices-modal-multipli"
      @update-plu="updatePlu"
    />
  </b-card>
</template>

<script>
/* eslint-disable no-plusplus */
/* eslint-disable no-multi-str */
/* eslint-disable vue/no-side-effects-in-computed-properties */

import Vue from 'vue'
import {
  GridPlugin, Sort, Filter, Selection, PdfExport, ExcelExport, Toolbar, Edit, Page, ColumnChooser, RowDD, ContextMenu, CommandColumn, Resize, Scroll,
} from '@syncfusion/ej2-vue-grids'
import { ToastPlugin } from '@syncfusion/ej2-vue-notifications'
// import { DropDownListPlugin } from '@syncfusion/ej2-vue-dropdowns'
import Ripple from 'vue-ripple-directive'
import { italian } from '@/@core/grid-translation/it-IT'
import { L10n, setCulture } from '@syncfusion/ej2-base';
import { BCard, BButton, BRow, BCol, VBToggle, BBadge } from 'bootstrap-vue'
import { getPlui, deletePlui } from '@/@core/api-service/devices/Plu'
// import { getListini } from '@/@core/api-service/catalog/priceList'
import SidebarPluEventHandler from './PluEventHandler/SidebarPluEventHandler.vue'
import AssociationMultiplePlu from './PluEventHandler/AssociationMultiplePlu.vue'

setCulture('it-IT');
//Vue.use(DropDownListPlugin)
L10n.load(italian)
/* L10n.load({
    'it-IT': {
        'grid': {
            'EmptyRecord': 'Nessun record',
            'GroupDropArea': "Trascina qui l'intestazione di una colonna per raggruppare la colonna",
            'UnGroup': 'Clicca qui per separare',
            'EmptyDataSourceError': 'DataSource non deve essere vuoto al caricamento iniziale perché le colonne di dataSource si trovano nella griglia di colonne AutoGenerate',
            'Item': 'Item',
            'Items': 'Items',
            'Add': 'Aggiungi',
            'Edit': 'Modifica',
            'Cancel': 'Annulla',
            'Delete': 'Elimina',
            'Print': 'Stampa',
            'Pdfexport': 'Esporta PDF',
            'Excelexport': 'Esporta Excel',
            'Wordexport': 'Esporta Word',
            'Csvexport': 'Esporta CSV',
            'Columnchooser': 'Colonne',
            'Save': 'Salva',
        },
        'pager':{
            'currentPageInfo': '{0} di {1} Pagine',
            'totalItemsInfo': '({0} Records)',
            'firstPageTooltip': 'Prima Pagina',
            'lastPageTooltip': 'Ultima Pagina',
            'nextPageTooltip': 'Pagina Successiva',
            'previousPageTooltip': 'Pagina Precedente',
            'nextPagerTooltip': 'Pagine Seguenti',
            'previousPagerTooltip': 'Pagine Precedenti'
        }
    }
});
 */
Vue.use(GridPlugin)
Vue.use(ToastPlugin)

export default Vue.extend({
  components: {
    BCard,
    BButton,
    // SettingsPdfExport,
    SidebarPluEventHandler,
    AssociationMultiplePlu,
    BRow,
    BCol,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data: () => ({
          wrapSettings: { wrapMode: 'Both' },
          cTemplate: function () {
          return { template : Vue.component('columnTemplate',{
             template: `<div class="image">
                    <img :src="image" :alt="altImage"/>
                </div>`,
                data: function() {
                    return {
                        data: {}
                    }
                },
                computed: {
                    image: function() {
                        return './' + this.data.EmployeeID + '.png'
                    },
                    altImage: function() {
                        return this.data.EmployeeID
                    }
                }
          })
          }
          },
    warehouse: {},
    commands: [
        { type: 'Elimina', id: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } },
        { type: 'Save', buttonOption: { iconCss: 'e-icons e-update', cssClass: 'e-flat' } },
        { type: 'Dettagli', id: 'Dettagli', target: '.e-column', buttonOption: { iconCss: 'e-icons e-folder-open', cssClass: 'e-flat' } }],
    contextMenuItems: ['AutoFit', 'AutoFitAll', 'SortAscending', 'SortDescending',
      'Copy', 'Save',
      'PdfExport', 'ExcelExport', 'CsvExport', 'FirstPage', 'PrevPage',
      'LastPage', 'NextPage',  'Group', 'Ungroup',
      {text: 'Dettagli', target: '.e-content', id: 'Dettagli', iconCss: 'e-icons e-folder-open' },
      {
        text: 'Elimina', tooltipText: 'Delete', iconCss: 'e-icons e-delete', id: 'DeleteCustom',
      },
    ],
    pageSettings: { pageSize: 25, pageSizes: [25,50,100,200], pageCount: 5 },
    showModalSettingPdfExport: false,
    editSettings: { allowFiltering: true, allowEditing: false, allowAdding: true, allowDeleting: true, mode: 'Normal' },
    toolbar: [{
        text: 'Elimina', tooltipText: 'Elimina', prefixIcon: 'e-delete', id: 'Elimina',
      },
      {
        text: 'Associazioni Multiple', tooltipText: 'Associazioni Multiple', prefixIcon: 'e-plus', id: 'AssociaMultipliReparti'
      },
      {
        text: 'Aggiungi', tooltipText: 'Aggiungi', prefixIcon: 'e-plus', id: 'Aggiungi',
      },
      'Cancel', 'ExcelExport', 'PdfExport', 'CsvExport', 'ColumnChooser', 'Print', 'Search'],
    toolbarOptions: ['ExcelExport'],
    ddData: [{ value: 1000, text: '1,000 plui and 11 Columns' }, { value: 10000, text: '10,000 plui and 11 Columns' }],
    ddValue: 10000,
    stTime: null,
    loadTime: null,
    dReady: false,
    isDataChanged: true,
    fields: { text: 'text', value: 'value' },
    plui: [],
    shopTemplate() {
      return {
        template: Vue.component('shopTemplate', {
          components: {
            BBadge,
          },
          data() { return { data: [], inactive: 'Inactive' } },
          template: `
            <div>
              <b-badge v-for="(el, index) in data.reparti" :variant="'primary'" :key="index" class="mx-1 my-50">
                <div v-if="el">{{ el.codice.concat('-').concat(el.nome) }}</div>
              </b-badge>
            </div>
          `,
        }),
      }
    },
    filterOptions: {
      type: 'Menu',
    },
    filter: {
      type: 'CheckBox',
    },
    selectionSettings: { persistSelection: false, type: 'Multiple', checkboxOnly: true },
  }),
  async mounted() {
    this.$refs.overviewgrid.ej2Instances.toolbarModule.enableItems(['AssociaMultipliReparti'], false)
    let dataSource = []
    const plu = await getPlui(JSON.stringify({ escludiAssociati: 'N'})).then(res => {
      console.log('getPlui', res)
      if (res.data.esito === 'OK') {
        // console.log('sono su ok getPlui')
        this.plui = [...res.data.plu.map(el => ({ ...el, prodotto: { id: el.idProdotto, nome: el.nome }}))]
        // this.plui = [...res.data.plu]
      }
    }).catch(e => e)
    /* getListini().then(res => {
      console.log('res listini', res)
      if (res.data.esito === 'OK') {
        this.plui.push(...res.data.listini)
      }
    }) */
    const internalPlu = [...this.plui]
    internalPlu.forEach((plu, index) => {
      Vue.set(plu, 'uniqueID', index)
    })
    // console.log('internalPLu', internalPlu)
    const pluFilterd = []
    internalPlu.forEach(internalSinglePlu => {
      const reparti = []
      internalPlu.forEach(pluAgain => {
        if (internalSinglePlu.id === pluAgain.id && internalSinglePlu.uniqueID !== pluAgain.uniqueID) {
          reparti.push(internalSinglePlu.reparti[0], pluAgain.reparti[0])
        } else {
          reparti.push(internalSinglePlu.reparti[0])
        }
      })
      pluFilterd.push({ ...internalSinglePlu, reparti: [...new Set(reparti)]})
    })
    pluFilterd.forEach(plu => {
      Vue.delete(plu, 'uniqueID')
    })
    const uniqueIds = []

    const unique = pluFilterd.filter(element => {
      // console.log('element', element)
      const isDuplicate = uniqueIds.includes(element.id)
      if (!isDuplicate) {
        uniqueIds.push(element.id)
        return true
      }
    })
    // console.log('unique', unique)
    this.plui = [...unique]
  },
  computed: {
  },
  methods: {
    onDeselectRow() {
      if (this.$refs.overviewgrid.getSelectedRecords().length === 0) {
        this.$refs.overviewgrid.ej2Instances.toolbarModule.enableItems(['AssociaMultipliReparti'], false)
      }
    },
    onRowSelected() {
      this.$refs.overviewgrid.ej2Instances.toolbarModule.enableItems(['AssociaMultipliReparti'], true)
      // console.log(this.selectedRow)
    },
    deletePlu(id) {
      // console.log('sono su deletePlui')
      let recordToDelete = []
      if (id === undefined) recordToDelete = this.$refs.overviewgrid.getSelectedRecords().map(el => ({ id: el.id }))
      else recordToDelete = id
      // console.log(recordToDelete, 'wow')
      deletePlui(recordToDelete).then(res => {
        if (res.data.esito === 'OK') {
          recordToDelete.forEach(record => this.$refs.overviewgrid.deleteRecord('id', record))
          // mixins
          // this.productDeletedCorrectlyAlert()
          this.$refs.toastRef.show({
            title: 'Operazione Completata!', content: 'Prodotto/i Eliminato/i Correttamente', cssClass: 'e-toast-success', icon: 'e-success toast-icons',
          })
        }
      }).catch(e => e)
    },
    updatePlu(shops, elementToDeleteWithNoID) {
      console.log('shops', shops)
      let typeOperation = ''
      shops.forEach(element => {
        // console.log('element', element)
        const index = this.$refs.overviewgrid.getRowIndexByPrimaryKey({ id: element.id})
        // console.log(index)
        if(index >= 0) {
          // console.log('dentro index >0')
          this.$refs.overviewgrid.updateRow(index, element)
          typeOperation = 'Aggiornato/i'
          // this.$refs.overviewgrid.deleteRecord()
        } else {
          typeOperation = 'Aggiunto/i'
          this.$refs.overviewgrid.addRecord(element, 0)
        }
      })
      this.$refs.toastRef.show({
        title: 'Operazione Completata!', content: `'PLU ${typeOperation} Correttamente'`, cssClass: 'e-toast-success', icon: 'e-success toast-icons',
      })
      this.$refs.overviewgrid.refresh()
      return
    },
    /* updatePlu(plu) {
      console.log('updatePlu', plu)
    }, */
    dataBound() {
      // console.log(this.$refs.overviewgrid)
      this.$refs.overviewgrid.autoFitColumns(['Azioni' ]);
    },
    commandColumm(args) {
      console.log('args', args.commandColumn.id)
      if(args.commandColumn.type === 'Dettagli') {
        this.$refs['plu-devices-sidebar-multipli'].setTitle('Modifica PLU')
        const data = {...args.rowData }
        this.$refs['plu-devices-sidebar-multipli'].setPluSidebar({ ...data })
        this.$refs['plu-devices-sidebar-multipli'].openSidebar()
      } else if (args.commandColumn.id === 'Delete') {
        this.alertAndConfirm([{ id: args.rowData.id }])
      }
    },
    contextMenuClick(args) {
        console.log('contextMenuClick', args)
        if(args.item.id === 'Dettagli') {
          this.$refs['plu-devices-sidebar-multipli'].setTitle('Modifica PLU')
          const data = {...args.rowInfo.rowData }
          this.$refs['plu-devices-sidebar-multipli'].setPluSidebar({ ...data })
          this.$refs['plu-devices-sidebar-multipli'].openSidebar()
        } else if (args.item.id === 'DeleteCustom') {
          this.alertAndConfirm([{ id: args.rowInfo.rowData.id }])
        }
    },
    closeModal() {
      this.showModalSettingPdfExport = false
    },
    actionTableBegin() {
      // console.log(action)
    },
    actionTableComplete() {
      // console.log(row)
      this.onDeselectRow()
      this.onRowSelected()
    },
    pdfHeaderQueryCellInfo() {
      // args.cell.row.pdfGrid.repeatHeader = true;
    },
    alertAndConfirm(id) {
      console.log('sono su alert confirm id', id)
      this.$swal({
        title: 'Sei Sicuro?',
        text: 'Verranno rimossi permanentemente',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'Annulla',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(res => {
        if (res.value) {
          this.deletePlu(id)
        }
      })
    },
     toolbarClick(args) {
        // console.log(args)
        if (args.item.id === 'Dettagli') {
          alert("Custom Toolbar Click...");
        }
        if (args.item.id === 'Elimina') {
          this.alertAndConfirm()
        }
        if (args.item.id === 'Aggiungi') {
          this.$refs['plu-devices-sidebar-multipli'].setTitle('Aggiungi PLU')
          this.$refs['plu-devices-sidebar-multipli'].resetPlu()
          this.$refs['plu-devices-sidebar-multipli'].openSidebar()
        }
        if (args.item.id === 'AssociaMultipliReparti' && this.$refs.overviewgrid.getSelectedRecords().length > 0) {
          this.$refs['plu-devices-modal-multipli'].setTitle('Associa multi reparti ai plu')
          this.$refs['plu-devices-modal-multipli'].resetPlu()
          this.$refs['plu-devices-modal-multipli'].setPluiByFather(this.$refs.overviewgrid.getSelectedRecords())
          this.$refs['plu-devices-modal-multipli'].openSidebar()
        }
        if (args.item.id === 'overviewgrid_pdfexport') { // 'Grid_pdfexport' -> Grid component id + _ + toolbar item name
          this.showModalSettingPdfExport = true
            const exportProperties = {
                //you can customize the name as per your requirement
                fileName: 'prova.pdf',
             }
            // this.$bvModal.show('setting-pdf-export-modal')
            this.$refs.overviewgrid.pdfExport(exportProperties)
            // this.$refs.overviewgrid.pdfExport(exportProperties)
        }
        if (args.item.id === 'overviewgrid_excelexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
            this.$refs.overviewgrid.excelExport()
        }
        if (args.item.id === 'overviewgrid_csvexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
            this.$refs.overviewgrid.csvExport()
        }
     },
    valueChange(args) {
      this.$refs.dropdown.$el.ej2_instances[0].closePopup()
      this.$refs.overviewgrid.showSpinner()
      this.isDataChanged = true
      this.stTime = null
      const contentElement = this.$refs.overviewgrid.ej2Instances.contentModule.content
      this.$refs.overviewgrid.ej2Instances.pageSettings.currentPage = 1
      contentElement.scrollLeft = 0
      contentElement.scrollTop = 0
      console.log(this.$refs.overviewgrid.ej2Instances.pageSettings.currentPage)
      this.stTime = performance.now()
      this.ddValue = args.value
      this.$refs.overviewgrid.hideSpinner()
    },
    load() {
      const proxy = this
      this.$refs.overviewgrid.$el.ej2_instances[0].on('data-ready', () => {
        proxy.dReady = true
      })
      this.$refs.overviewgrid.$el.addEventListener('DOMSubtreeModified', () => {
        if (proxy.dReady && proxy.stTime && proxy.isDataChanged) {
          const e = performance.now() - proxy.stTime
          proxy.loadTime = `Load Time: <b>${e.toFixed(0)}</b><b>ms</b>`
          proxy.stTime = null
          proxy.dReady = false
          proxy.isDataChanged = false
          // proxy.$refs.msgelement.classList.remove('e-hide')
        }
      })
    },
  },
  provide: {
    grid: [Toolbar, PdfExport, ExcelExport, Filter, Selection, Sort, Edit, Page, ColumnChooser, RowDD, ContextMenu, CommandColumn, Resize, Scroll, Filter],
  },

})

</script>
<style scoped>
/* @import "../../../../../../node_modules/@syncfusion/ej2-vue-grids/styles/bootstrap.css"; */
</style>
