/* eslint-disable  */
import axios from 'axios'

export function getPlui(data) {
  // console.log('dentro logiiinnn')
  return new Promise((resolve, reject) => {
    axios.post(process.env.VUE_APP_URL_API_SERVER, data,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
          classe: 'Dev-Plu',
          metodo: 'getPlui',
          ACL: '',
        },
      })
      .then(res => resolve(res))
      .catch(error => reject(error))
  })
}

export function getPluiPuntoVendita(shop) {
  // console.log('dentro logiiinnn')
  return new Promise((resolve, reject) => {
    axios.post(process.env.VUE_APP_URL_API_SERVER, shop,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
          classe: 'Dev-Plu',
          metodo: 'getPluiPuntoVendita',
          ACL: '',
        },
      })
      .then(res => resolve(res))
      .catch(error => reject(error))
  })
}

export function setPlui(plu) {
  // console.log('dentro logiiinnn')
  return new Promise((resolve, reject) => {
    axios.post(process.env.VUE_APP_URL_API_SERVER, plu,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
          classe: 'Dev-Plu',
          metodo: 'setPlu',
          ACL: '',
        },
      })
      .then(res => resolve(res))
      .catch(error => reject(error))
  })
}

export function deletePlui(plui) {
    // console.log('dentro reparti', reparti)
    return new Promise((resolve, reject) => {
      axios.post(process.env.VUE_APP_URL_API_SERVER, plui,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
            classe: 'Dev-Plu',
            metodo: 'deletePlu',
            ACL: '',
          },
        })
        .then(res => resolve(res))
        .catch(error => reject(error))
    })
  }
export function setPluRepository(repo) {
    // console.log('dentro reparti', reparti)
    return new Promise((resolve, reject) => {
      axios.post(process.env.VUE_APP_URL_API_SERVER, repo,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
            classe: 'Dev-PluRepository',
            metodo: 'setPluRepository',
            ACL: '',
          },
        })
        .then(res => resolve(res))
        .catch(error => reject(error))
    })
  }

export function getPluiRepository(repo) {
  // console.log('dentro reparti', reparti)
  return new Promise((resolve, reject) => {
    axios.post(process.env.VUE_APP_URL_API_SERVER, repo,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
          classe: 'Dev-PluRepository',
          metodo: 'getPluiRepository',
          ACL: '',
        },
      })
      .then(res => resolve(res))
      .catch(error => reject(error))
  })
}
export function invioPluRepository(repo) {
  // console.log('dentro reparti', reparti)
  return new Promise((resolve, reject) => {
    axios.post(process.env.VUE_APP_URL_API_SERVER, repo,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
          classe: 'Dev-PluRepository',
          metodo: 'invioPluRepository',
          ACL: '',
        },
      })
      .then(res => resolve(res))
      .catch(error => reject(error))
  })
}