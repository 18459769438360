import axios from 'axios'

export function getProdotti() {
  // console.log('dentro logiiinnn')
  return new Promise((resolve, reject) => {
    axios.post(process.env.VUE_APP_URL_API_SERVER, {},
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
          classe: 'Cat-Prodotto',
          metodo: 'getProdotti',
          ACL: '',
        },
      })
      .then(res => resolve(res))
      .catch(error => reject(error))
  })
}
export function getProdottiNonAssociati() {
  // console.log('dentro logiiinnn')
  return new Promise((resolve, reject) => {
    axios.post(process.env.VUE_APP_URL_API_SERVER, {},
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
          classe: 'Cat-Prodotto',
          metodo: 'getProdottiNonAssociati',
          ACL: '',
        },
      })
      .then(res => resolve(res))
      .catch(error => reject(error))
  })
}
export function getProdottiUnificatii() {
  // console.log('dentro logiiinnn')
  return new Promise((resolve, reject) => {
    axios.post(process.env.VUE_APP_URL_API_SERVER, {},
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
          classe: 'Cat-Prodotto',
          metodo: 'getProdottiUnificati',
          ACL: '',
        },
      })
      .then(res => resolve(res))
      .catch(error => reject(error))
  })
}
export function getProdotto(prodotti) {
  // console.log('dentro logiiinnn')
  return new Promise((resolve, reject) => {
    axios.post(process.env.VUE_APP_URL_API_SERVER, prodotti,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
          classe: 'Cat-Prodotto',
          metodo: 'getProdotto',
          ACL: '',
        },
      })
      .then(res => resolve(res))
      .catch(error => reject(error))
  })
}
export function getProdottiMagazzino(magazzino) {
  // console.log('dentro logiiinnn')
  return new Promise((resolve, reject) => {
    axios.post(process.env.VUE_APP_URL_API_SERVER, magazzino,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
          classe: 'Cat-Prodotto',
          metodo: 'getProdottiMagazzino',
          ACL: '',
        },
      })
      .then(res => resolve(res))
      .catch(error => reject(error))
  })
}
export function setProdottoCaratteristiche(prodottoCaratteristiche) {
  // console.log('dentro logiiinnn')
  return new Promise((resolve, reject) => {
    axios.post(process.env.VUE_APP_URL_API_SERVER, prodottoCaratteristiche,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
          classe: 'Cat-Prodotto',
          metodo: 'setProdottoCaratteristiche',
          ACL: '',
        },
      })
      .then(res => resolve(res))
      .catch(error => reject(error))
  })
}
export function setProdottoNonAssociato(prodottoDaAssociare) {
  // console.log('dentro logiiinnn')
  return new Promise((resolve, reject) => {
    axios.post(process.env.VUE_APP_URL_API_SERVER, prodottoDaAssociare,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
          classe: 'Cat-Prodotto',
          metodo: 'setProdottoNonAssociato',
          ACL: '',
        },
      })
      .then(res => resolve(res))
      .catch(error => reject(error))
  })
}
